<template>
    <div>
        <!-- <van-nav-bar title="借支" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div class="top">
            <div>
                <van-badge :content="count.audit ? count.audit : ''" max="99">
                    <div :class="{select:tab_select == 1, tab_select: true}" @click="tabSwitch(1)">待审核</div>
                </van-badge>
                <van-badge :content="count.pending_loan ? count.pending_loan : ''" max="99">
                    <div :class="{select:tab_select == 2, tab_select: true}" @click="tabSwitch(2)">待放款</div>
                </van-badge>
                <van-badge :content="count.loan ? count.loan : ''" max="99">
                    <div :class="{select:tab_select == 3, tab_select: true}" @click="tabSwitch(3)">已放款</div>
                </van-badge>
                <van-badge :content="count.refuse ? count.refuse : ''" max="99">
                    <div :class="{select:tab_select == 4, tab_select: true}" @click="tabSwitch(4)">驳回</div>
                </van-badge>
            </div>
        </div>
        <div class="dingbukongbai"></div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" @load="onLoad(page)" :offset="150">
                <div v-if="list.length" class="block">
                    <div v-for="item in list" :key="item.index" class="card" @click="goBorrowingDetails(item.id)">
                        <div>
                            <div class="weight">{{item.factory_name}}</div>
                            <div class="col_666666 font_26">借支金额：{{item.advanced_money}}元</div>
                            <div class="col_666666 font_26" v-if="tab_select == 2">审批金额：{{item.audit_money}}元</div>
                            <div class="col_666666 font_26" v-if="tab_select == 3">放款金额：{{item.audit_money}}元</div>
                            <div class="col_666666 font_26" v-if="tab_select == 1 || tab_select == 4">申请时间：{{item.gmt_create}}</div>
                            <div class="col_666666 font_26" v-if="tab_select != 1">{{tab_select == 2 ? '审批' : (tab_select == 3 ? '放款' : '驳回')}}时间：{{item.gmt_modified}}</div>
                        </div>
                        <div class="details">详情
                            <van-icon name="arrow" />
                        </div>
                    </div>
                    <div class="dibukongbai"></div>
                </div>
                <div v-else class="null-data">
                    <div>
                        <img :src="src+'null_index.png'" />
                        <div>暂无数据</div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
        <!-- 底部 -->
        <!-- <div class="news_footer">
            <div class="news_footer_on" @click="goApplyMoney" >申请借支</div>
        </div> -->
        <div class="footer_two_btn">
            <div class="footer_two_btn_cd">
                <div class="footer_two_btn_cd_lt" @click="gobanck">返回</div>
                <div class="footer_two_btn_cd_rt" @click="goApplyMoney">申请借支</div>
                <!-- <div class="footer_two_btn_cd_rt" @click="goApplyMoney" v-if="count.audit <= 0 && count.pending_loan <= 0 && is_fac == 1">申请借支</div> -->
                <!-- <div class="footer_two_btn_cd_rt d8" v-else>申请借支</div> -->
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/css/index.css'
import config from "@/utils/config.js";
import Vue from 'vue';
import { Badge } from 'vant';

Vue.use(Badge);
export default {
    data() {
        return {
            // 分页=======================================
            loading: false,
            finished: false,
            refreshing: false,
            page: 1,//请求第几页
            total: 0,//总共的数据条数
            // ==========================================
            src: `${config.ossImageUrl}`,
            list: [],
            issucc_po: false,
            is_fac: 1,
            count: {},
            tab_select: 1, // 1：待审核  2：待放款  3：已放款  4：驳回
        };
    },
    activated() {
        this.$store.commit("hideTabbar");
        if (this.$store.state.refreshList) {
            this.list = []
            this.getList()
            this.getCount()
        }
    },
    mounted() {
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.is_fac = this.$route.query.is_fac
        this.factory_id = this.$route.query.factory_id // 工厂id
        this.getList()
        this.getCount()
    },
    methods: {
        gobanck() {
             this.$router.replace({ path: '/home' })
        },
        getCount() {
            var datas = {
                team_id: localStorage.getItem('team_id')
            }
            this.Request('client/advanced_payment/list/count', 'get', datas).then((res) => {
                if (res.status == 0) {
                    this.count = res.data.count
                }
            })
        },
        // 跳转到申请借支
        goApplyMoney() {
            this.$router.push({ path: '/applyMoney' });
        },
        tabSwitch(value) {
            this.issucc_po = false;
            this.finished = false;
            this.page = 1
            this.tab_select = value
            this.getList();
            this.getCount()
        },
        onClickLeft() {
            this.$router.go(-1)
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1
            this.getList();
            this.getCount()
        },
        // 跳转到借支详情
        goBorrowingDetails(id) {
            this.$router.push({ path: '/borrowingDetails', query: { borrowing_id: id, type: this.tab_select } });
        },
        // 调接口，获取列表数据
        getList(page = 1) {
            if (this.issucc_po) {
                return false
            }
            this.issucc_po = true;
            // this.finished = false;
            if (page == 1) this.list = []
            if (this.refreshing) {
                this.list = [];
                this.refreshing = false;
            }
            this.$store.state.refreshList = false
            var data = {
                team_id: localStorage.getItem('team_id'),
                factory_id: 0,
                info_type: this.tab_select,
                limits: 20,
                page: page
            }
            this.page++
            this.Request('client/advanced_payment/list', 'get', data).then((res) => {
                if (res.status == 0) {
                    if (page == 1) {
                        this.list = res.data.list
                    } else {
                        this.list = this.list.concat(res.data.list)
                    }

                    this.loading = false;
                    this.issucc_po = false;
                    if (res.data.list.length != 20) {
                        this.finished = true;
                    }
                    // if (this.list.length >= res.data.count) {
                    //     this.finished = true;
                    // }
                }
            })
        },
        onLoad(page) {
            // this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            // this.getList()
            if (page != 1) {
                this.getList(page)
            }
        },
    }
};
</script>

<style scoped>
.van-badge__wrapper> .van-badge{
    right:-5px !important;
}
.dingbukongbai {
    width: 100%;
    height: 106px;
}
.dibukongbai {
    width: 100%;
    height: 60px;
}
/* 头部固定 */
.top {
    position: fixed;
    width: calc(100% - 60px);
    z-index: 2;
    background: #fff;
    padding: 0 30px;
    /* margin-top: 90px; */
    border-bottom: 1px solid #f8f8f8;
}
.top > div {
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
}
.top .tab_select {
    margin-top: 0px;
    padding-bottom: 15px;
    border-bottom: 6px solid transparent;
}
.top .select {
    border-bottom: 6px solid #0abbb9;
    color: #0abbb9;
}

.top .express {
    border-radius: 40px;
    height: 30px;
    min-width: 18px;
    background: red;
    color: #fff;
    position: relative;
    line-height: 30px;
    padding: 0 6px;
    text-align: center;
    margin: -4px 0 0 -10px;
    top: -12px;
    display: inline-block;
}

/* 卡片 */
.block {
    position: relative;
    min-height: 10px;
    /* top: 106px; */
}
.card {
    background-color: #fff;
    padding: 20px 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
}
.details {
    color: #0abbb9;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.details i {
    vertical-align: text-top;
}
.card .weight {
    font-size: 30px;
    margin-bottom: 10px;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: #1a1a1a;
}
.card > div {
    line-height: 45px;
}

.van-pull-refresh {
    overflow: initial;
}
</style>